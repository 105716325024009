var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('div',[_c('validation-observer',{ref:"provider"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"9"}},[_c('b-form-group',{attrs:{"label":"Question","label-for":"question"}},[_c('validation-provider',{attrs:{"name":"question","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","state":errors.length > 0 ? false : null,"placeholder":"question"},model:{value:(_vm.form.question),callback:function ($$v) {_vm.$set(_vm.form, "question", $$v)},expression:"form.question"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',{attrs:{"label":"Order","label-for":"order"}},[_c('validation-provider',{attrs:{"name":"order","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false : null,"placeholder":"order"},model:{value:(_vm.form.order),callback:function ($$v) {_vm.$set(_vm.form, "order", _vm._n($$v))},expression:"form.order"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"1"}},[_c('b-form-group',{attrs:{"label":"Publish","label-for":"isPublish"}},[_c('validation-provider',{attrs:{"name":"isPublish"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"type":"number","state":errors.length > 0 ? false : null},model:{value:(_vm.form.isPublish),callback:function ($$v) {_vm.$set(_vm.form, "isPublish", $$v)},expression:"form.isPublish"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Answer","label-for":"answer"}},[_c('validation-provider',{attrs:{"name":"answer","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"type":"text","state":errors.length > 0 ? false : null,"placeholder":"answer"},model:{value:(_vm.form.answer),callback:function ($$v) {_vm.$set(_vm.form, "answer", $$v)},expression:"form.answer"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.questionId ? 'Edit' : 'Create')+" ")])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }